/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third party
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import CasePost from './Layout/CasePost'

const Section = styled.section`
  padding-bottom: 12rem;
  font-family: ${({ theme }) => theme.font.family.primary};
`

const CasesBlock = styled.div`
  position: relative;
  z-index: 2;
  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: rgba(252, 206, 20, 0.8);
  }
`

const Inner = styled.div`
  position: relative;
  z-index: 2;
  background-color: rgba(252, 206, 20, 0.8);
  mix-blend-mode: multiply;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;

  @media (min-width: 992px) {
    padding: 9rem 18rem 9rem 0;
  }

  @media (max-width: 991px) {
    padding: 2rem 1rem 8rem 1rem;
  }
`

const Image = styled(Plaatjie)`
  position: absolute !important;
  top: 0;
  left: 0;
  border-radius: 50px;
  filter: grayscale(100%);
  opacity: 0.2;
`

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Cases: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpCase,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.casesQueryQuery>(graphql`
    query casesQuery {
      allWpCase(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalCaseFragment
          }
        }
      }
    }
  `)

  const cases: unknown = allWpCase.edges

  return (
    <Section className="position-relative">
      <BlogBink
        posts={cases as BlogBinkPosts}
        id="cases"
        limit={Number(fields.limit)}
      >
        <>
          <BlogGrid fields={fields} />
        </>
      </BlogBink>
    </Section>
  )
}

const PostsWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -8rem;
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <CasesBlock>
            <Inner className="w-100 h-100">
              <ParseContent content={fields.description} className="mb-5" />
            </Inner>
            <Image
              image={fields.backgroundimage}
              alt=""
              className="w-100 h-100"
            />
          </CasesBlock>
        </div>
      </div>
      <PostsWrapper className="row justify-content-center">
        <div className="col-lg-9">
          <div className="row justify-content-start">
            {blogBink.posts.map((post, index) => {
              const isOdd = index % 2

              const { node }: any = post

              return (
                <div key={post.node.id} className="col-lg-11 mb-4">
                  <CasePost node={node} fields={fields} isOdd={isOdd} />
                </div>
              )
            })}
          </div>
        </div>
      </PostsWrapper>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default Cases
