import React from 'react'

// Third party
import styled from 'styled-components'

// Elements
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import LinkPrimary from 'components/elements/Links/LinkPrimary'
import ParseContent from 'components/shared/ParseContent'

// Images
import Location from 'img/location.inline.svg'
import Clock from 'img/clock.inline.svg'
import Suitcase from 'img/suitcase.inline.svg'
import Trencher from 'img/trencher.inline.svg'

interface VacancyPostProps {
  fields: any
  node: {
    id: string
    title: string
    uri: string
    recap: {
      education: string
      excerpt: string
      hours: string
      jobtitle: string
      location: string
    }
  }
}

const Vacancy = styled(BlockPrimary)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & .icon {
    width: 20px;
  }

  & a {
    width: 200px;
  }

  @media (min-width: 576px) {
    padding: 4rem 6rem;
  }
`

const Excerpt = styled(ParseContent)`
  & p {
    font-weight: 400 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (min-width: 576px) {
      padding-right: 6rem;
    }
  }
`

const VacancyPost: React.FC<VacancyPostProps> = ({ node, fields }) => {
  const { education, excerpt, hours, jobtitle, location } = node.recap

  return (
    <Vacancy>
      <h2>{node.title}</h2>
      <div className="d-flex flex-column flex-sm-row align-items-sm-center w-100">
        <div className="d-flex align-items-center me-5">
          <Location className="icon" />
          <span className="ms-2">{location}</span>
        </div>
        <div className="d-flex align-items-center me-5">
          <Clock className="icon" />
          <span className="ms-2">{hours}</span>
        </div>
        <div className="d-flex align-items-center me-5">
          <Suitcase className="icon" />
          <span className="ms-2">{jobtitle}</span>
        </div>
        <div className="d-flex align-items-center me-5">
          <Trencher className="icon" />
          <span className="ms-2">{education}</span>
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row align-items-end justify-content-between mt-5">
        <Excerpt content={excerpt} className="pe-5" />
        <LinkPrimary to={node.uri}>{fields.readmoretext}</LinkPrimary>
      </div>
    </Vacancy>
  )
}

export default VacancyPost
