/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

// Third party
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import BlogPost from 'components/flex/Posts/Layout/BlogPost'

// Images
import Dropdown from 'img/dropdown.svg'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
`

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            databaseId
            name
            wpParent {
              node {
                databaseId
                name
              }
            }
            wpChildren {
              nodes {
                id
                databaseId
                name
                wpParent {
                  node {
                    databaseId
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  const mainCategories = allWpCategory.edges.filter(
    (category) => !category.node.wpParent
  )

  const subCategories = allWpCategory.edges.filter(
    (category) => category.node.wpParent
  )

  return (
    <Section className="mb-5 pb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={subCategories}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogFilters mainCategories={mainCategories} fields={fields} />
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </Section>
  )
}

const Select = styled(motion.div)`
  background: url(${Dropdown}) 90% center no-repeat;
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 50px;
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-style: italic !important;
  color: #989898;

  & h2 {
    font-size: ${({ theme }) => theme.font.size.medium};
    margin-bottom: 0;
  }

  @media (min-width: 576px) {
    width: 139px;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
`

const FilterWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 3rem 3rem 7rem 3rem;
`

interface CustomBlogFilterProps {
  id: string
  className?: string
  value?: string
}

const CustomBlogFilter: React.FC<CustomBlogFilterProps> = ({
  children,
  id,
  className = '',
  value,
}) => {
  const blogBink = useBlogBink()

  return (
    <button
      type="button"
      className={`${className} blog-bink-filter`}
      data-active={blogBink.isSelected(id) ? 1 : 0}
      onClick={() => {
        blogBink.setSelectedFilters(id)
      }}
      tabIndex={0}
      value={value}
      style={{
        background: '#FFF',
      }}
    >
      {children}
    </button>
  )
}

const StyledCustomBlogFilter = styled(CustomBlogFilter)<{
  selected: boolean
  borderBottom: boolean
}>`
  background: transparent !important;
  text-align: center;
  width: 100%;
  padding: 1rem;
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
  border-bottom: ${(props) =>
    props.borderBottom ? '1px solid #f4f4f4' : 'unset'};

  &:last-child {
    border-bottom: unset;
  }

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`

const Categories = styled(BlockPrimary)`
  position: absolute;
  top: 40px;
  border-radius: 24px;
  background: ${({ theme }) => theme.color.light};
  z-index: 2;

  @media (min-width: 992px) {
    left: -75px;
  }

  @media (min-width: 576px) {
    min-width: 300px;
  }
`

interface BlogFiltersProps {
  mainCategories: any
  fields: any
}

interface BlogFilterProps {
  node: {
    id: string
    name: string
    wpParent: any
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = ({
  mainCategories,
  fields,
}: any) => {
  const [open, setOpen] = useState(-1)
  const blogBink = useBlogBink()

  return (
    <FilterWrapper>
      <div className="container">
        <ParseContent content={fields.description} />
        <div className="row justify-content-center">
          <div className="col-lg-6 d-flex flex-column flex-sm-row justify-content-between mt-4">
            {mainCategories.map((mainCategory: any, index: number) => (
              <motion.div
                className="position-relative"
                onHoverStart={() => setOpen(index)}
                onHoverEnd={() => setOpen(-1)}
              >
                <Select
                  role="button"
                  className="mb-4 mb-sm-0"
                  onTap={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                  onTapCancel={() => setOpen(-1)}
                >
                  <h2>{mainCategory.node.name}</h2>
                </Select>
                {open === index && (
                  <Categories className="p-0">
                    {blogBink.categories
                      .filter(
                        (category: BlogFilterProps) =>
                          category.node.wpParent.node.databaseId ===
                          mainCategory.node.databaseId
                      )
                      .map(
                        (category: BlogFilterProps, categoryIndex: number) => (
                          <StyledCustomBlogFilter
                            key={`${category.node.id}-${categoryIndex}`}
                            id={category.node.id}
                            selected={blogBink.isSelected(category.node.id)}
                            borderBottom={
                              blogBink.categories.filter(
                                (c: BlogFilterProps) =>
                                  c.node.wpParent.node.databaseId ===
                                  mainCategory.node.databaseId
                              ).length > 1
                            }
                            value={category.node.id}
                          >
                            <>{category.node.name}</>
                          </StyledCustomBlogFilter>
                        )
                      )}
                  </Categories>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </FilterWrapper>
  )
}

const PostsWrapper = styled.div`
  margin-top: -7rem;
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <PostsWrapper className="row justify-content-center">
        {blogBink.posts.length > 0 &&
          blogBink.posts.map((post, index) => {
            const { node }: any = post

            return (
              <div key={post.node.id} className="col-sm-6 col-md-4 mt-5">
                <BlogPost node={node} index={index} />
              </div>
            )
          })}
        {blogBink.posts.length < 1 && (
          <>
            <h2 className="mt-4">
              Helaas! We kunnen (nog) geen artikelen tonen in deze categorie.
            </h2>
            <p>Probeer een andere categorie</p>
          </>
        )}
      </PostsWrapper>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default Blog
