import React from 'react'

// Third party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import LinkPrimary from 'components/elements/Links/LinkPrimary'
import ParseContent from 'components/shared/ParseContent'

const Case = styled(BlockPrimary)<{ isOdd: number }>`
  background-color: ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.light};
  border-radius: 50px !important;
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & .icon {
    width: 20px;
  }

  & a {
    width: 260px;
  }

  @media (min-width: 992px) {
    padding: 4rem 6rem;
  }

  @media (min-width: 576px) {
    ${(props) =>
      props.isOdd &&
      css`
        margin-left: 8rem;
        margin-right: -8rem;
      `}
  }
`

const Excerpt = styled(ParseContent)`
  & p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const Image = styled(Plaatjie)<{ isOdd: number }>`
  border-radius: 25px;

  @media (min-width: 992px) {
    width: 255px;
    height: 255px;

    ${(props) =>
      props.isOdd
        ? css`
            margin-left: -14rem;
          `
        : css`
            margin-right: -14rem;
          `}
  }

  @media (max-width: 991px) {
    width: 255px;
    height: 255px;
  }

  @media (max-width: 575px) {
    width: 125px;
    height: 125px;
    margin-bottom: 1rem;
  }
`

interface CasePostProps {
  fields: any
  isOdd: number
  node: {
    id: string
    title: string
    uri: string
    recap: {
      quote: {
        description: string
      }
      thumbnail: any
    }
  }
}

const CasePost: React.FC<CasePostProps> = ({ node, fields, isOdd }) => {
  const { quote, thumbnail } = node.recap

  const check = isOdd === 1

  return (
    <Case className="position-relative" isOdd={isOdd}>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
        {check && (
          <>
            <div className="d-flex d-sm-none justify-content-start w-100">
              <Image image={thumbnail} alt="" isOdd={isOdd} />
            </div>
            <div className="d-sm-block d-none">
              <Image image={thumbnail} alt="" isOdd={isOdd} />
            </div>
          </>
        )}
        <div className={`${isOdd ? 'ps-5' : ''}`}>
          <h2>{node.title}</h2>

          <Excerpt content={quote.description} />
          <div
            className={`d-flex ${
              isOdd ? 'justify-content-end' : 'justify-content-start'
            }`}
          >
            <LinkPrimary
              colorPrimary
              arrowColor="#FFF"
              to={node.uri}
              className="mt-5"
            >
              {fields.readmoretext}
            </LinkPrimary>
          </div>
        </div>
        {!isOdd && (
          <>
            <div className="d-flex d-sm-none justify-content-end w-100">
              <Image image={thumbnail} alt="" isOdd={isOdd} />
            </div>
            <div className="d-sm-block d-none">
              <Image image={thumbnail} alt="" isOdd={isOdd} />
            </div>
          </>
        )}
      </div>
    </Case>
  )
}

export default CasePost
