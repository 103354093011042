/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third party
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import VacancyPost from 'components/flex/Posts/Layout/VacancyPost'

// Images
import BackgroundCircle from 'img/backgroundcircle.inline.svg'

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
  padding-bottom: 5rem;
`

const BackgroundWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  top: -150px;
  left: 0;
  z-index: -1;
  transform: rotate(180deg);
`

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Vacancies: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacanciesQueryQuery>(graphql`
    query vacanciesQuery {
      allWpVacancy(sort: { order: DESC, fields: date },filter: {databaseId: {nin: [1564,1565,1566]}}) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const vacancies: unknown = allWpVacancy.edges

  return (
    <Section className="position-relative" id="vacatures">
      <BackgroundWrapper>
        <BackgroundCircle />
      </BackgroundWrapper>
      <BlogBink
        posts={vacancies as BlogBinkPosts}
        id="vacancies"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </Section>
  )
}

const PostsWrapper = styled.div``

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <ParseContent content={fields.description} className="mb-5" />
      <PostsWrapper className="row">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-lg-12 mb-4">
              <VacancyPost node={node} fields={fields} />
            </div>
          )
        })}
      </PostsWrapper>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default Vacancies
